import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OpenAssignmentsComponent } from './component/assignment/open-assignments/open-assignments.component';
import { FormComponent } from './component/form/form.component';
import { MyprofilComponent } from './component/myprofil/myprofil.component';
import { AcceptAsCustomerComponent } from './layouts/accept-as-customer/accept-as-customer.component';

import { FullComponent } from './layouts/full/full.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
      },
      {
        path: 'component/assignment',
        loadChildren: () => import('./component/assignment/assignment.module').then(m => m.AssignmentModule)
      },
      // {
        // path: '**',
        // component: 404
      // }
    ]
  },
  {
    path: 'accept-assignment/:id',
    component: AcceptAsCustomerComponent
  }
];
